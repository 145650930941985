import * as React from 'react';
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

import {
  HealthJourneyRoutes,
  Home,
  LandingPage,
  CareCollectionsRoutes,
  AboutMeRoutes,
  CareDiscoveryRoutes,
  HealthProfileRoutes,
} from 'components/LazyRoutes';
import ErrorPage from 'pages/Error404';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingIndicator } from '@manulife-web/shared-module';
import { BasePage } from './BasePage';

export const AuthViewLogout = () => {
  const { logout } = useAuth0();
  React.useEffect(() => {
    logout({
      returnTo: window.location.origin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingIndicator />;
};

function AppRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/sign-out" component={AuthViewLogout} />
      <BasePage>
        <Switch>
          <Route path="/home" component={Home} exact key="home" />
          <Route
            path={[
              getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
              getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
              getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
              getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
            ]}
            component={HealthJourneyRoutes}
            key="health-journey"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
            component={HealthProfileRoutes}
            key="health-profile"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
            component={CareCollectionsRoutes}
            key="care-collections"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
            component={AboutMeRoutes}
            key="about-me"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
            component={CareDiscoveryRoutes}
            key="care-discovery"
          />
          <Route component={ErrorPage} />
        </Switch>
      </BasePage>
    </Switch>
  );
}
export default AppRoutes;
