import React, { useRef } from 'react';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Box } from '@leagueplatform/genesis-core';
import { useListItemsFocusHook } from '../../hooks/use-list-items-focus/use-list-items-focus-hook';
import { usePreventScroll } from '../../hooks/use-prevent-scroll/use-prevent-scroll.hook';
import {
  DropdownMegaTooltip,
  DropdownMegaTooltipMobile,
  Overlay,
} from './user-dropdown.dropdown-styles';
import { DropdownList } from './user-dropdown.dropdown-list.component';
import type { UserDropdownProps } from './user-dropdown.types';

export const UserDropdown = ({
  isOpen,
  isMobile,
  setIsOpen,
  dropdownItemsConfig = [],
  getDropdownItemAnalyticsProps,
}: UserDropdownProps) => {
  const refsMenuItems: any[] = [];
  const refContainer = useRef();
  useListItemsFocusHook(isOpen, refsMenuItems, refContainer);
  usePreventScroll(isMobile && isOpen);
  const formattedName = (action: string) =>
    action?.toLocaleLowerCase()?.replace(/_/g, ' ');

  const onDropdownClick = (action: string) => {
    setIsOpen(false);
    trackAnalyticsEvent(
      EVENT_NAME.BUTTON_CLICKED,
      getDropdownItemAnalyticsProps?.(formattedName(action)),
    );
  };

  // For Mobile Version
  if (isMobile) {
    return (
      <Box>
        {isOpen && <Overlay onClick={() => setIsOpen(false)} />}
        <DropdownMegaTooltipMobile
          as="ul"
          isOpen={isOpen}
          isMobile={isMobile}
          id="user_dropdown_menu"
          role="dialog"
          ref={refContainer}
          hidden={!isOpen}
        >
          <DropdownList
            dropdownItemsConfig={dropdownItemsConfig}
            onDropdownClick={onDropdownClick}
            isMobile={isMobile}
            refsMenuItems={refsMenuItems}
          />
        </DropdownMegaTooltipMobile>
      </Box>
    );
  }

  // For Desktop Version
  return (
    <DropdownMegaTooltip
      as="ul"
      isOpen={isOpen}
      // isMobile={isMobile}
      id="user_dropdown_menu"
      role="dialog"
      ref={refContainer}
      hidden={!isOpen}
    >
      <DropdownList
        dropdownItemsConfig={dropdownItemsConfig}
        onDropdownClick={onDropdownClick}
        isMobile={isMobile}
        refsMenuItems={refsMenuItems}
      />
    </DropdownMegaTooltip>
  );
};
