import * as React from 'react';

import { AppNavBarComponent } from 'components/header-nav/Nav';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { useLocation } from '@leagueplatform/routing';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => {
  // Scroll to top on location change
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{ position: 'relative', flex: '1', overflowX: 'hidden', ...css }}
      >
        {children}
      </Box>
    </>
  );
};
