import type { DropdownSection } from '@manulife-web/shared-module';
import { useAuth0 } from '@auth0/auth0-react';

const userDropdownConfig: DropdownSection[] = [
  [
    {
      to: '/sign-out',
      text: 'LOG_OUT',
      linkProps: {
        color: '$onSurfaceTextCritical',
      },
    },
  ],
];

export const useUserDropdownComponent = () => {
  const { user } = useAuth0();

  const dropdownMenuItemProps = {
    preferredFirstName: user?.nickname ?? 'John Doe',
    menuItemsConfig: userDropdownConfig,
  };

  return { data: dropdownMenuItemProps };
};
