import React from 'react';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { useConfigProperty } from '@leagueplatform/core';
import { Spinner, Box } from '@leagueplatform/genesis-core';

export interface LoadingIndicatorProps {
  inline?: string | boolean;
}

const LoaderContainer = styled(Box)`
  min-height: 300px;
  position: relative;
  ${(props: LoadingIndicatorProps) =>
    !props.inline &&
    `
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `};
`;

const IndicatorContainer = styled.div`
  width: 200px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingText = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

export const LoadingIndicator = ({ inline }: LoadingIndicatorProps) => {
  const { formatMessage } = useIntl();
  const loadingConfig = useConfigProperty('core.ui.components.loading');

  const loadingComponent = loadingConfig?.element || (
    <Spinner loading renderLoadingText={false} />
  );

  const loadingMessage =
    loadingConfig?.text || formatMessage({ id: 'LOADING' });

  return (
    <LoaderContainer inline={inline}>
      <IndicatorContainer>
        {loadingComponent}
        {loadingConfig?.text !== false && (
          <LoadingText>{loadingMessage}</LoadingText>
        )}
      </IndicatorContainer>
    </LoaderContainer>
  );
};
