import * as React from 'react';
import {
  AppBarComponent,
  AppBarItem,
  AppBarLink,
  AppBarNavLink,
  UserDropdownComponent,
} from '@manulife-web/shared-module';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { GDSTheme } from '@leagueplatform/genesis-core';
import { setConfig } from '@leagueplatform/core';

import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { UserDropdownItemProps } from '@manulife-web/shared-module/src/components/user-dropdown/user-dropdown.types';
import { UserDropdown } from './user-dropdown/user-dropdown.component';
import { LinkType, NavLink, useNavLinksList } from './use-nav-links-list.hook';
import { manulifeTheme as manulifeLightTheme } from '../../theme/theme';
import { manulifeDarkTheme } from '../../theme/ManulifeDark';

const APP_NAV_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid

type ThemeOptions = 'manulife_dark' | 'manulife_light';

const THEME_LABEL_MAP: { [k in ThemeOptions]: string } = {
  manulife_light: 'MANULIFE_LIGHT',
  manulife_dark: 'MANULIFE_DARK',
};

const THEME_FILE_MAP: { [k in ThemeOptions]: GDSTheme } = {
  manulife_light: manulifeLightTheme,
  manulife_dark: manulifeDarkTheme,
};

export const AppNavBarComponent = () => {
  const activeTheme = localStorage.getItem(
    'MANULIFE_ACTIVE_THEME',
  ) as ThemeOptions;

  const [theme, setTheme] = React.useState<ThemeOptions>(
    activeTheme || 'manulife_light',
  );

  React.useEffect(() => {
    setConfig((config) => {
      // eslint-disable-next-line no-param-reassign
      config.core.ui.theme = THEME_FILE_MAP[theme];
      return config;
    });
    localStorage.setItem('MANULIFE_ACTIVE_THEME', theme);
  }, [theme]);

  const { navLinks } = useNavLinksList();
  const { formatMessage } = useIntl();
  const sendNavBarAnalyticsEvent = (action: string) => () =>
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: 'Navigation Bar',
      detail: action,
    });

  const ThemeDropdownOptions = (
    Object.keys(THEME_LABEL_MAP) as ThemeOptions[]
  ).reduce((acc, curr) => {
    acc.push({
      text: THEME_LABEL_MAP[curr],
      to: '',
      onClick: () => setTheme(curr),
    });
    return acc;
  }, [] as UserDropdownItemProps[]);

  const navLinkWrapper = (navLink: NavLink) => {
    switch (navLink.type) {
      case LinkType.LINK:
        return (
          <AppBarLink
            as={Link}
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarLink>
        );
      case LinkType.NAV:
        return (
          <AppBarNavLink
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarNavLink>
        );
      default:
        throw new Error('invalid link type');
    }
  };

  return (
    <AppBarComponent navHeight={APP_NAV_DEFAULT_HEIGHT}>
      {/* Spacer */}
      <AppBarItem
        css={{
          flexGrow: 2,
          '@mobile': {
            flexGrow: 0,
          },
          '@mobileLandscape': {
            flexGrow: 0,
          },
          '@tablet': {
            flexGrow: 0,
          },
        }}
        aria-hidden="true"
      />
      {/* App Nav Home & Capability Areas */}
      {navLinks.map((navLink) => navLinkWrapper(navLink))}

      {/* Spacer */}
      <AppBarItem
        css={{
          flexGrow: 30,
          '@mobile': {
            flexGrow: 1,
          },
          '@mobileLandscape': {
            flexGrow: 1,
          },
          '@tablet': {
            flexGrow: 1,
          },
        }}
      />

      <AppBarItem>
        <UserDropdownComponent
          label={`Active theme: ${formatMessage({
            id: THEME_LABEL_MAP[theme],
          })}`}
          dropdownItemsConfig={[ThemeDropdownOptions]}
        />
      </AppBarItem>

      {/* App Nav User Dropdown */}
      <AppBarItem>
        <UserDropdown />
      </AppBarItem>
      {/* Spacer */}
      <AppBarItem
        css={{
          flexGrow: 2,
          '@mobile': {
            flexGrow: 0,
          },
          '@mobileLandscape': {
            flexGrow: 0,
          },
          '@tablet': {
            flexGrow: 0,
          },
        }}
        aria-hidden="true"
      />
    </AppBarComponent>
  );
};
