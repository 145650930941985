import './index.css';

import React, { Suspense, useEffect, useMemo } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorState, LoadingIndicator, log } from '@manulife-web/shared-module';
import { BrowserRouter } from '@leagueplatform/routing';
import League, {
  LEAGUE_MODULE_NAMES,
  LeagueConfig,
} from '@leagueplatform/core';
import { useAuth0 } from '@auth0/auth0-react';
import AppRoutes from './components/AppRoutes';
import { getConfig } from './config';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
});

log('test message');

function useTheme(defaultTheme = 'manulife_light') {
  useEffect(() => {
    let activeTheme = localStorage.getItem('MANULIFE_ACTIVE_THEME');
    if (!activeTheme) {
      activeTheme = defaultTheme;
      localStorage.setItem('MANULIFE_ACTIVE_THEME', activeTheme);
    }
  }, [defaultTheme]);
}

const App = () => {
  const { getAccessTokenSilently, isLoading, user, loginWithRedirect, error } =
    useAuth0();

  useTheme();

  const config: LeagueConfig = useMemo(
    () => getConfig(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  if (isLoading) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!user) {
    loginWithRedirect();
  }

  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
};

export default App;
