import React from 'react';

import {
  Box,
  Divider,
  styled,
  TextAction,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { Link as RouterLink } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import type { UserDropdownItemProps } from './user-dropdown.types';

const NotificationDot = styled(Box, {
  borderRadius: '$circle',
  position: 'absolute',
  top: '50%',
  right: '$minusOne',
  backgroundColor: '$criticalIcon',
  width: '$half',
  height: '$half',
  transform: 'translateY(-50%)',
});

/**
 * @param {string} to - Path to go to for RouterLink
 * @param {string} text - Display text
 * @param {string} onClick - function to invoke when item is clicked
 * @param {string} ref - To control the focus state, this is forwarded from the parent
 * @param {boolean} hasNotification - Test for League Family notification dots
 */
export const UserDropdownItem = React.forwardRef(
  (
    {
      to,
      text,
      isMobile,
      onClick = () => {},
      target,
      hasNotification,
      linkProps = {},
      isLastElementInSection,
    }: UserDropdownItemProps,
    ref,
  ) => {
    const { formatMessage } = useIntl();

    const isExternalLink = to && /^[^(/|..)]/.test(to);

    const Component = (() => {
      if (isExternalLink) return TextAction;
      return RouterLink;
    })();

    return (
      <Box as="li">
        <TextAction
          as={Component}
          to={!isExternalLink ? to : undefined}
          href={isExternalLink ? to : undefined}
          ref={ref}
          onClick={onClick}
          target={target}
          aria-describedby={
            hasNotification ? 'dependent-notification-message' : ''
          }
          css={{
            '&:hover': {
              textDecoration: 'none !important',
              backgroundColor: '$interactiveBackgroundHovered',
            },
            textAlign: 'left',
            ...(isMobile ? { fontWeight: 'bold' } : { fontWeight: 'regular' }),
            ...(isMobile
              ? { color: '$interactiveActionSubdued' }
              : { color: '$onSurfaceTextPrimary' }),
            display: 'block',
            padding: '$half',
            marginBottom: '$quarter',
            border: 'none',
            backgroundColor: 'transparent',
            width: '100%',
            typography: '$bodyTwo',
            ...linkProps,
          }}
        >
          <Box as="span" css={{ position: 'relative' }}>
            {formatMessage({ id: text })}
            {target === '_blank' && (
              <VisuallyHidden data-testid="new-tab-msg">
                {formatMessage({ id: 'OPENS_IN_A_NEW_TAB' })}
              </VisuallyHidden>
            )}
            {/* Testing a notification dot for League Family */}
            {hasNotification && (
              <NotificationDot
                aria-hidden
                id="dependent-notification-message"
                aria-label={formatMessage({
                  id: 'DEPENDENT_NOTIFICATION_MESSAGE',
                })}
              />
            )}
          </Box>
        </TextAction>
        {isLastElementInSection && (
          <Divider
            css={{
              marginLeft: '$half',
              marginRight: '$half',
              width: 'unset',
              borderWidth: '1px',
              ...(isMobile && { marginTop: '$oneAndHalf' }),
            }}
          />
        )}
      </Box>
    );
  },
);
