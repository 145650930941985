import * as React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { history } from '@leagueplatform/routing';
import App from './App';

const onRedirectCallback = (appState: any) => {
  history?.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname,
  );
};

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_AUDIENCE } =
  import.meta.env;

const providerConfig = {
  domain: VITE_AUTH0_DOMAIN,
  clientId: VITE_AUTH0_CLIENT_ID,
  audience: VITE_AUTH0_AUDIENCE,
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
