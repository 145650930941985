import React, { useEffect, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  Button,
  GDSButtonProps,
  HeadingText,
  ImageMessage,
  ParagraphText,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';
import { WEB_COMMON_ASSET_KEYS } from '../../types/assets.types';

const defaultTryAgainHandler = () => window.location.reload();

export interface ErrorStateProps {
  imageSrc?: string;
  headingId?: string;
  bodyString?: string;
  headingString?: string;
  buttonText?: string;
  bodyId?: string;
  buttonId?: string;
  buttonPriority?: GDSButtonProps<'button'>['priority'];
  hideButton?: boolean;
  hideImage?: boolean;
  onButtonClick?: () => void;
  labelledbyId?: string;
  describedbyId?: string;
  emphasisText?: string;
}

const ErrorContent = ({
  headingId = 'THERE_SEEMS_T0_HAVE_BEEN_A_PROBLEM',
  buttonText,
  bodyString,
  headingString,
  bodyId,
  buttonId = 'TRY_AGAIN',
  hideButton = false,
  hideImage,
  onButtonClick = defaultTryAgainHandler,
  labelledbyId,
  describedbyId,
  buttonPriority = 'primary',
  emphasisText,
}: Exclude<ErrorStateProps, 'imageSrc'>) => {
  const { formatMessage } = useIntl();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    buttonRef?.current?.focus();
  });

  return (
    <StackLayout
      css={{ marginTop: !hideImage ? '$three' : 'none', textAlign: 'center' }}
      horizontalAlignment="center"
      spacing="$twoAndHalf"
    >
      <StackItem>
        <StackLayout horizontalAlignment="center" spacing="$half">
          <HeadingText level="2" size="lg" id={labelledbyId}>
            {headingString || formatMessage({ id: headingId })}
          </HeadingText>
          {(bodyId || bodyString) && (
            <ParagraphText id={describedbyId}>
              {bodyString || formatMessage({ id: bodyId })}
            </ParagraphText>
          )}
        </StackLayout>
        {emphasisText && (
          <ParagraphText>
            <strong>{emphasisText}</strong>
          </ParagraphText>
        )}
      </StackItem>

      {!hideButton && (
        <StackItem>
          <Button
            onClick={onButtonClick}
            ref={buttonRef}
            priority={buttonPriority}
          >
            {buttonText || formatMessage({ id: buttonId })}
          </Button>
        </StackItem>
      )}
    </StackLayout>
  );
};

export const ErrorState = ({
  hideImage = false,
  imageSrc,
  ...props
}: ErrorStateProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (hideImage) return <ErrorContent {...props} />;

  const errorStateImage = handleStaticAsset(
    WEB_COMMON_ASSET_KEYS.WEB_COMMON_ERROR_STATE_IMAGE,
  ) as string;
  return (
    <ImageMessage image={imageSrc || errorStateImage}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ErrorContent {...props} />
    </ImageMessage>
  );
};
