import React, { useRef, useState } from 'react';

import { trackAnalyticsEvent, EventProps } from '@leagueplatform/analytics';
import { Box, queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { useHandleDropdown } from '../../hooks/use-handle-dropdown/use-handle-dropdown-hook';

import { UserBar } from './user-dropdown.user-bar.component';
import { UserDropdown } from './user-dropdown.dropdown.component';
import type { DropdownSection } from './user-dropdown.types';

type UserDropdownComponentProps = {
  label: string;
  dropdownItemsConfig: DropdownSection[];
  userBarProps?: any;
  enableMobileRedesign?: boolean;
  userBarAnalyticsProps?: { name: string; props: any };
  getDropdownItemAnalyticsProps?: (action: string) => EventProps;
};

/**
 * @param {string} label - Text to use on the dropdown user bar
 * @param {string} dropdownItemsConfig - a list dropdown menu sections, each section containing a list of dropdown items
 * @param {object} userBarProps - any additional properties to pass to the user bar display for the user dropdown component (color and image overrides, etc)
 * @param {object} userBarAnalyticsProps - analytics event name and properties
 * @param {function} getDropdownItemAnalyticsProps - a function that returns the analytics properties when a dropdown item is clicked
 */
export const UserDropdownComponent = ({
  label,
  dropdownItemsConfig = [],
  userBarProps = {},
  userBarAnalyticsProps,
  enableMobileRedesign = false,
  getDropdownItemAnalyticsProps = (action: string) => ({
    detail: action,
    product_area: userBarAnalyticsProps?.props?.product_area,
    screen_name: userBarAnalyticsProps?.props?.detail,
  }),
}: UserDropdownComponentProps) => {
  const isMobile = Boolean(
    useMediaQuery(queryHelpers.down('mobileLandscape')) && enableMobileRedesign,
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useHandleDropdown({
    isOpen: isDropdownOpen,
    setIsOpen: setIsDropdownOpen,
    ref: dropdownRef,
  });

  const toggleDropdown = (event: any) => {
    if (isDropdownOpen === false) {
      trackAnalyticsEvent(
        userBarAnalyticsProps?.name || '',
        userBarAnalyticsProps?.props,
      );
    }
    event.stopPropagation(); // think this is needed so it doesn't bubble up to the body click
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Box
      color="$onSurfaceTextPrimary"
      css={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '@mobile': {
          padding: 'static',
        },
      }}
      ref={dropdownRef}
    >
      <UserBar
        label={label}
        isOpen={isDropdownOpen}
        onClick={toggleDropdown}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...userBarProps}
      />
      <UserDropdown
        isOpen={isDropdownOpen}
        isMobile={isMobile}
        setIsOpen={setIsDropdownOpen}
        dropdownItemsConfig={dropdownItemsConfig}
        getDropdownItemAnalyticsProps={getDropdownItemAnalyticsProps}
      />
    </Box>
  );
};
