/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { NavLink } from '@leagueplatform/routing';
import { Box, TextAction } from '@leagueplatform/genesis-core';

type AppBarItemProps = {
  children?: React.ReactNode;
  isMobile?: boolean;
  [k: string]: any;
};

type AppBarLinkItemProps = AppBarItemProps & {
  listItemProps?: object;
};

const commonNavStyles = {
  display: 'flex',
  textAlign: 'center',
  position: 'relative',
  alignSelf: 'stretch',
  alignItems: 'center',
  paddingX: '$half',
  '&:hover': {
    color: '$interactiveActionPrimary',
  },
  '@tablet': {
    paddingX: '$quarter',
  },
  '@mobile': {
    paddingX: '$none',
  },
  // Properties below are used to match current archetype nav while using Genesis Core components, ideally we don't override these properties
  color: '$onSurfaceTextPrimary',
  fontWeight: '500',
  textDecoration: 'none !important',
  letterSpacing: 'normal',
};

const StyledLink = ({ children, ...props }: AppBarItemProps) => (
  <TextAction
    size="medium"
    emphasis="subtle"
    css={{
      ...commonNavStyles,
    }}
    {...props}
  >
    <Box as="span">{children}</Box>
  </TextAction>
);

const StyledNavLink = ({ children, ...props }: AppBarItemProps) => (
  <StyledLink
    as={NavLink}
    css={{
      ...commonNavStyles,
      '&[aria-current="page"]': {
        color: '$interactiveActionPrimary',
      },
      '&[aria-current="page"]:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: -1,
        left: 0,
        backgroundColor: '$interactiveActionHovered',
        height: '$quarter',
        borderRadius: '$large',
        width: '100%',
        '@mobile': {
          backgroundColor: 'transparent',
        },
      },
    }}
    {...props}
  >
    {children}
  </StyledLink>
);

/**
 * A List item wrapper that can be used inside the AppBarComponent.
 * @param {node} children - child components, any component you want to display as a nav item
 * @param {object} props - any additional properties to pass to the flex "li" component
 */
export const AppBarItem = ({ children, ...props }: AppBarItemProps) => (
  <Box
    as="li"
    css={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex' }}
    {...props}
  >
    {children}
  </Box>
);

/**
 * A Link that can be used inside the AppBarComponent.
 * @param {node} children - child components, usually a string to be displayed in the link
 * @param {object} listItemProps - object of properties to be passed exclusively to the li parent of the link
 * @param {object} props - any additional properties to pass to the link component (onClick, to, etc)
 */

export const AppBarLink = ({
  children,
  listItemProps,
  ...props
}: AppBarLinkItemProps) => (
  <AppBarItem {...listItemProps}>
    <StyledLink {...props}>{children}</StyledLink>
  </AppBarItem>
);

/**
 * A NavLink that can be used inside the AppBarComponent.
 * @param {node} children - child components, usually a string to be displayed in the link
 * @param {object} listItemProps - object of properties to be passed exclusively to the li parent of the link
 * @param {object} props - any additional properties to pass to the link component (onClick, to, etc)
 */
export const AppBarNavLink = ({
  children,
  listItemProps,
  ...props
}: AppBarLinkItemProps) => (
  <AppBarItem {...listItemProps}>
    <StyledNavLink {...props}>{children}</StyledNavLink>
  </AppBarItem>
);
